import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Admin, Resource } from 'react-admin';

import { CategoryList, CategoryCreate, CategoryEdit } from "./categories";
import { SetList, SetCreate, SetEdit, SetShow } from "./sets";


import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  FirebaseRealTimeSaga
} from 'react-admin-firebase';

const config = {
  apiKey: "AIzaSyDsVqErPZP650Rl8NMex40Xz-yL3ScG6ow",
  authDomain: "fpe-minigames.firebaseapp.com",
  databaseURL: "https://fpe-minigames.firebaseio.com",
  projectId: "fpe-minigames",
  storageBucket: "fpe-minigames.appspot.com",
  messagingSenderId: "557006546890",
  appId: "1:557006546890:web:1479a269af834058ba1133"
};

const options = {
  logging: true,
  rootRef: 'prototypes/R3oPfRGjknkolqTfhEP4'
}

const dataProvider = FirebaseDataProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        dataProvider={dataProvider}
      >
        <Resource
          name="categories"
          list={CategoryList}
          create={CategoryCreate}
          edit={CategoryEdit}
        />
        <Resource
          name="sets"
          list={SetList}
          create={SetCreate}
          show={SetShow}
          edit={SetEdit}
        />
      </Admin>
    );
  }
}

export default App;
