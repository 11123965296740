import * as React from "react";
import { TabbedForm, FormTab, ReferenceInput, ReferenceField, NumberField, Tab, TabbedShowLayout, ArrayField, ArrayInput, BooleanField, BooleanInput, ChipField, Create, Datagrid, Edit, EditButton, Filter, List, RichTextField, SelectInput, Show, ShowButton, SimpleForm, SimpleFormIterator, SimpleShowLayout, SingleFieldList, TextField, TextInput } from "react-admin";

export const SetList = (props) => (
    <List {...props} >
        <Datagrid>
            <TextField source="title.de" />
            <ReferenceField label="category" source="category" reference="categories">
                <ChipField source="id" />
            </ReferenceField>
            <ShowButton />
            <EditButton />
            {/* <DeleteButton label="" redirect={false}/> */}
        </Datagrid>
    </List>
);

export const SetShow = (props) => (
    < Show {...props}>
        <SimpleShowLayout>
            <TextField source="title.de" />
            <ReferenceField label="category" source="category" reference="categories">
                <ChipField source="id" />
            </ReferenceField>
            <TextField source="titleGroup1.de" />
            <ArrayField source="group1.de">
                <Datagrid>
                    <TextField source="title" />
                    <TextField source="imageUrl" />
                </Datagrid>
            </ArrayField>
            <TextField source="titleGroup2.de" />
            <ArrayField source="group2.de">
                <Datagrid>
                    <TextField source="title" />
                    <TextField source="imageUrl" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show >
);

export const SetCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="title.de" />
            <TextInput source="titleGroup1.de" />
            <ArrayInput source="group1.de">
                <SimpleFormIterator>
                    <TextInput source="title" label="Title" />
                    <TextInput source="imageUrl" label="Image URL(optional)" />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="titleGroup2.de" />
            <ArrayInput source="group2.de">
                <SimpleFormIterator>
                    <TextInput source="title" label="Title" />
                    <TextInput source="imageUrl" label="Image URL(optional)" />
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceInput label="Category" source="category" reference="categories">
                <SelectInput optionText="id" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);


export const SetEdit = (props) => (<Edit {...props}>
    <SimpleForm>
        <TextInput source="title.de" />
        <TextInput source="titleGroup1.de" />
        <ArrayInput source="group1.de">
            <SimpleFormIterator>
                <TextInput source="title" label="Title" />
                <TextInput source="imageUrl" label="Image URL(optional)" />
            </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="titleGroup2.de" />
        <ArrayInput source="group2.de">
            <SimpleFormIterator>
                <TextInput source="title" label="Title" />
                <TextInput source="imageUrl" label="Image URL(optional)" />
            </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput label="Category" source="category" reference="categories">
            <SelectInput optionText="id" />
        </ReferenceInput>
    </SimpleForm>
</Edit>)

