import * as React from "react";
import { TabbedForm, FormTab, DeleteButton, LongTextInput, NumberInput, NumberField, Tab, TabbedShowLayout, ArrayField, ArrayInput, BooleanField, BooleanInput, ChipField, Create, Datagrid, Edit, EditButton, Filter, List, RichTextField, SelectInput, Show, ShowButton, SimpleForm, SimpleFormIterator, SimpleShowLayout, SingleFieldList, TextField, TextInput } from "react-admin";


export const CategoryList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title.de" />
            <EditButton />
            <DeleteButton label="" redirect={false} />
        </Datagrid>
    </List>
);

export const CategoryCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="id" label="id" />
            <TextInput source="title.de" label="Title" />
        </SimpleForm>
    </Create>
);


export const CategoryEdit = (props) => (<Edit {...props}>
    <SimpleForm>
        <TextInput source="id" label="id" />
        <TextInput source="title.de" label="Title" />
    </SimpleForm>
</Edit>)

